import { Typography } from '@material-ui/core';
import { Trip, TripStatus } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HostApi, TripWithVersionApi } from '../../api/RpcClient';
import { useAuthContext } from '../../utils/AuthContext';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { TripForm, TripSchema, TripValues } from './components/TripForm';

export const TripAddPage: React.FC = () => {
  const history = useHistory();
  const [callWithToast] = useToast('trip');
  const authContext = useAuthContext();
  const supplierProfileId = authContext.isLoggedIn ? authContext.account.supplierProfileId : undefined;
  const accountId = authContext.isLoggedIn ? authContext.account.accountId : undefined;
  if (!supplierProfileId || !accountId) {
    return null;
  }
  return (
    <LoadingGuard
      loadData={async () => {
        const hosts = await HostApi.getHostOptions();
        const tripOptions = await TripWithVersionApi.getRecommendedTripOptions();
        // get articles categories from contentful
        const articleCategories = await TripWithVersionApi.getContentfulCategories();
        const landings = await TripWithVersionApi.getContentfulLandings();
        return { hosts, tripOptions, articleCategories, landings };
      }}
    >
      {({ hosts, tripOptions, articleCategories, landings }) => (
        <div>
          <Typography gutterBottom variant="h2">
            Add new trip
          </Typography>
          <TripForm
            hosts={hosts}
            tripOptions={tripOptions}
            articleCategories={articleCategories}
            landings={landings}
            onSubmit={async (values: TripValues) => {
              const { hostProfileId, createdById, status, active, featured, deleted, hidden, featuredPosition, ...tripVersionValues } = TripSchema.cast(values);
              const trip: Omit<Trip, 'tripId'> = {
                supplierProfileId,
                hostProfileId,
                createdById,
                uploaderId: accountId,
                status: (status ?? TripStatus.Draft) as TripStatus,
                active: false,
                featured: featured ?? false,
                hidden: hidden ?? false,
                featuredPosition: featuredPosition ?? 0,
                deleted: deleted ?? false,
                createdAt: new Date(),
                modifiedAt: new Date(),
              };
              // making first image of the gallery as cover image
              if (tripVersionValues.gallery && tripVersionValues.gallery.length > 0) {
                // Ensure coverImage has the correct structure as per UploadedImage type
                const galleryImage = tripVersionValues.gallery[0];
                if (galleryImage && 'hash' in galleryImage && 'lqip' in galleryImage) {
                  tripVersionValues.coverImage = galleryImage;
                }
              }
              // adding lower-casing slug here. ideally this should be enforced in TripForm yup validation but it applies to Add and Edit forms
              // since existing trip slugs cannot be updated adding this here
              if (tripVersionValues.slug?.trim()) tripVersionValues.slug = tripVersionValues.slug.trim().toLowerCase();
              const tripId = await callWithToast(TripWithVersionApi.createTripWithVersion(trip, tripVersionValues));
              history.push(`/trips/${tripId.toString()}`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
