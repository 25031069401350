import React from 'react';
import { Grid, Checkbox, InputAdornment } from '@material-ui/core';
import { UploadedImage } from '@tripr/common';
import { Field, FieldRenderProps } from 'react-final-form';
import { ImagesApi } from '../../api/ImagesApi';
import { FormList } from '../common/FormList';
import { MyTextField } from '../common/forms/TextField';
import { MultiImageUploader } from '../common/MultiImageUploader';

const uploadActivityImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('activity', file);

const CheckboxWrapper: React.FC<FieldRenderProps<any, HTMLElement>> = ({ input, label }) => <Checkbox {...input} />;

export const ExtraActivities: React.FC<{ name: string }> = ({ name }) => (
  <FormList
    name={name}
    newValue={() => ({
      title: '',
      price: 1,
      description: '',
      photos: [],
      isVisible: false,
    })}
    render={p => (
      <Grid container spacing={2}>
        <Grid item>
          <Field component={CheckboxWrapper} name={`${p.parentName}.isVisible`} label="Activity Visibility" xs={4} type="checkbox" />
        </Grid>
        <Field component={MyTextField} name={`${p.parentName}.title`} label="Activity Title" xs={4} variant={'filled'} />
        <Field
          component={MyTextField}
          name={`${p.parentName}.price`}
          label="Price"
          // xs={4}
          variant={'filled'}
          type={'number'}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Field component={MyTextField} name={`${p.parentName}.description`} label="Description" xs={4} variant={'filled'} multiline />
        <MultiImageUploader name={`${p.parentName}.photos`} uploader={uploadActivityImg} size={[240, 240]} downloadable />
      </Grid>
    )}
  />
);
