import { useSnackbar, WithSnackbarProps } from 'notistack';
import { ReactNode, useEffect, useReducer } from 'react';

export type NoProps = { children?: ReactNode | undefined };

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const moveInArray = <T>(array: T[], from: number, to: number): T[] => {
  const clone = [...array];
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  return clone;
};

export const printDiff = (a: any, b: any, path = '') => {
  if (a === b) {
    return true;
  }

  console.log(path, a, b);
  if (Array.isArray(a) && Array.isArray(b)) {
    const common = Math.min(a.length, b.length);
    for (let i = 0; i < common; i += 1) {
      const subPath = `${path}[${i}]`;
      printDiff(a[i], b[i], subPath);
    }
    a.slice(common).map((ai, i) => console.log(`${path}[${i}]`, ai, undefined));
    b.slice(common).map((bi, i) => console.log(`${path}[${i}]`, bi, undefined));
    return false;
  }

  if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
    const aKeys = Object.keys(a);
    for (const property of aKeys) {
      const subPath = `${path}.${property}`;
      printDiff(a[property], b[property], subPath);
    }
    for (const property of Object.keys(b)) {
      if (aKeys.indexOf(property) < 0) {
        const subPath = `${path}.${property}`;
        printDiff(a[property], b[property], subPath);
      }
    }
  }

  return false;
};

export const useOnce = (action: () => void) => {
  useEffect(action, []);
};

export const withToast = async <T>(props: WithSnackbarProps, name: string, func: Promise<T>): Promise<T> => {
  try {
    const result = await func;
    props.enqueueSnackbar(`Successfully saved ${name}`, { variant: 'success' });
    window.scrollTo(0, 0);
    return result;
  } catch (e) {
    props.enqueueSnackbar(`Saving ${name} failed`, { variant: 'error' });
    window.scrollTo(0, 0);
    throw e;
  }
};

type CallWithToast = <T>(func: Promise<T>) => Promise<T>;

export const useToast = (name: string): [CallWithToast] => {
  const snackbar = useSnackbar();
  return [func => withToast(snackbar, name, func)];
};

export const useForceRefresh = (): (() => void) => {
  return useReducer(() => ({}), {})[1] as () => void;
};

export const downloadBlob = (blob: Blob, fileName?: string) => {
  alert('download blob called');
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  // Required for Firefox
  document.body.appendChild(link);

  link.href = url;
  link.download = fileName || `download-${Date.now()}`;
  link.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
