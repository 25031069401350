import { Account, Image, RpcReadResponse, UploadedImage } from '@tripr/common';
import { Configuration } from './Config';
import { authManager } from './Firebase';

interface ApiResponse<T> {
  result: T;
}

export const makeApiCall = async <T>(
  method: 'GET' | 'POST',
  path: string,
  requestBody: BodyInit | null = null,
  contentType = 'application/json',
): Promise<T> => {
  const token = await authManager.getToken();
  if (!token) {
    throw new Error('Not authenticated');
  }

  const url = `${Configuration.ApiUrl}/${path}`;
  // alert('make api called');
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...(contentType ? { 'Content-Type': contentType } : {}),
    },
    body: requestBody,
  });

  if (!response.ok) {
    if (response.status == 404) throw new Error('Could not find the resource');
    throw new Error('API call failed');
  }

  // Check content type of response
  const responseContentType = response.headers.get('content-type');
  if (responseContentType?.includes('application/json')) {
    return response.json();
  } else {
    // First cast to unknown, then to the expected type
    return response.blob() as unknown as Promise<T>;
  }
};

export class ImagesApi {
  public async uploadImage(_bucket: string, file: Blob | string): Promise<UploadedImage> {
    const formData = new FormData();
    formData.append('file', file);
    const response = await makeApiCall<ApiResponse<UploadedImage>>('POST', `Images/origin`, formData, '');
    return response.result;
  }

  public static async downloadImage(hash: string): Promise<Blob> {
    const blob = await makeApiCall<Blob>('GET', `Images/download-image?hash=${hash}`, null, 'application/octet-stream');

    return new Blob([blob], { type: 'application/octet-stream' });
  }
}

export const GetImageUrl = (image: Image | string, width: number, height: number) =>
  typeof image === 'string'
    ? image
    : `${Configuration.ImagesCdnUrl}/all/${image.hash}?width=${width * 2}&height=${height * 2}&optimizer=gif&aspect_ratio=${width}:${height}`;

export const GetImageUrlFullSize = (imageHash: string) => `${Configuration.ImagesCdnUrl}/all/${imageHash}?optimizer=gif`;

export class AuthApi {
  public async getOrCreate(): Promise<RpcReadResponse<Account>> {
    return makeApiCall<RpcReadResponse<Account>>('POST', `Auth/getOrCreate`, '');
  }
}
