import { Grid } from '@material-ui/core';
// import { RelatedArticleTags, TripId } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MySelect } from '../../common/forms/MySelect';

export const RelatedDestinationsList: React.FC<{ name: string; destinations: { [key: string]: string } }> = ({ name, destinations }) => (
  <FormList<string>
    name={name}
    newValue={() => ''}
    render={p => (
      <Grid container spacing={1}>
        <Field component={MySelect} name={`${p.parentName}`} label="Tag" xs={12} options={destinations} />
      </Grid>
    )}
  />
);
