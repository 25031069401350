import {
  Account,
  AccountId,
  EmailReg,
  HostId,
  HostProfile,
  Inventory,
  InventoryId,
  Review,
  ReviewId,
  Coupon,
  CouponId,
  RpcClientType,
  SupplierId,
  SupplierProfile,
  Trip,
  TripId,
  TripVersion,
  TripVersionId,
  TripWithVersion,
  Reservation,
  ReservationId,
  isoCountryMap,
  TripStatus,
} from '@tripr/common';
import _ from 'lodash';
import { makeApiCall } from './ImagesApi';
import { ContentfulApiClient } from './ContentfulClient';

const RpcClient: RpcClientType = async request => {
  const paramsSerialized = JSON.stringify(request.request).replace(/"/g, '').substr(0, 100);
  const path = `SuppliersRpc?c=${request.controller}_${request.method}_${paramsSerialized}`;
  const response = await makeApiCall('POST', path, JSON.stringify(request));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  request.cb(response);
};

export class AccountApi {
  public static getAccounts = () =>
    new Promise<Account[]>((resolve, reject) => {
      RpcClient({
        controller: 'Account',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createAccount = (host: Omit<Account, 'accountId'>) =>
    new Promise<AccountId>((resolve, reject) => {
      RpcClient({
        controller: 'Account',
        method: 'create',
        request: { entity: host },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });
}

export class EmailRegApi {
  public static getEmailRegs = () =>
    new Promise<EmailReg[]>((resolve, reject) => {
      RpcClient({
        controller: 'EmailReg',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });
}

export class HostApi {
  public static getHosts = () =>
    new Promise<HostProfile[]>((resolve, reject) => {
      RpcClient({
        controller: 'HostProfile',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getHostOptions = async (): Promise<{ [key: string]: string }> => {
    const hostProfiles = await HostApi.getHosts();
    return _.mapValues(_.keyBy(hostProfiles, 'hostId'), v => `${v.firstName} ${v.lastName}`);
  };

  public static getHost = (hostId: HostId) =>
    new Promise<HostProfile>((resolve, reject) => {
      RpcClient({
        controller: 'HostProfile',
        method: 'read',
        request: { entityIds: [hostId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createHost = (host: Omit<HostProfile, 'hostId'>) =>
    new Promise<HostId>((resolve, reject) => {
      RpcClient({
        controller: 'HostProfile',
        method: 'create',
        request: { entity: host },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateHost = (host: HostProfile) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'HostProfile',
        method: 'update',
        request: { entity: host },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class SupplierApi {
  public static getSuppliers = () =>
    new Promise<SupplierProfile[]>((resolve, reject) => {
      RpcClient({
        controller: 'SupplierProfile',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getSupplier = (supplierId: SupplierId) =>
    new Promise<SupplierProfile>((resolve, reject) => {
      RpcClient({
        controller: 'SupplierProfile',
        method: 'read',
        request: { entityIds: [supplierId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createSupplier = (supplier: Omit<SupplierProfile, 'supplierId'>) =>
    new Promise<SupplierId>((resolve, reject) => {
      RpcClient({
        controller: 'SupplierProfile',
        method: 'create',
        request: { entity: supplier },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateSupplier = (supplier: SupplierProfile) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'SupplierProfile',
        method: 'update',
        request: { entity: supplier },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class InventoryApi {
  public static getInventoryList = (tripId: TripId) =>
    new Promise<Inventory[]>((resolve, reject) => {
      RpcClient({
        controller: 'Inventory',
        method: 'read',
        request: { filters: { tripId } },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getInventory = (inventoryId: InventoryId) =>
    new Promise<Inventory>((resolve, reject) => {
      RpcClient({
        controller: 'Inventory',
        method: 'read',
        request: { entityIds: [inventoryId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createInventory = (inventory: Omit<Inventory, 'inventoryId'>) =>
    new Promise<InventoryId>((resolve, reject) => {
      RpcClient({
        controller: 'Inventory',
        method: 'create',
        request: { entity: inventory },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateInventory = (inventory: Inventory) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Inventory',
        method: 'update',
        request: { entity: inventory },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });

  public static deleteInventory = (inventoryId: InventoryId) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Inventory',
        method: 'delete',
        request: { entityId: inventoryId },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

type TripOptions = {
  includeSlug?: boolean;
};

export class TripWithVersionApi {
  public static getWithVersionList = () =>
    new Promise<TripWithVersion[]>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'getWithVersion',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getWithVersion = (tripId: TripId) =>
    new Promise<TripWithVersion>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'getWithVersion',
        request: { entityIds: [tripId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createTripWithVersion = async (trip: Omit<Trip, 'tripId'>, tripVersion: Omit<TripVersion, 'tripId' | 'tripVersionId'>): Promise<TripId> => {
    const tripId = await TripApi.createTrip(trip);
    const tripVersionId = await TripVersionApi.createTripVersion({ ...tripVersion, tripId });
    await TripApi.updateTrip({ ...trip, tripId, currentVersionId: tripVersionId });
    return tripId;
  };

  public static updateTripWithVersion = async (trip: Trip, tripVersion: TripVersion) => {
    await TripApi.updateTrip(trip);
    await TripVersionApi.updateTripVersion(tripVersion);
  };

  public static archiveTrip = async (tripId: TripId) => {
    const { currentVersion, uploader, ...trip } = await TripWithVersionApi.getWithVersion(tripId);
    await TripApi.updateTrip({ ...trip, status: TripStatus.Archive });
  };

  public static toggleTripActiveStatus = async (tripId: TripId) => {
    // toggles trip active status in DB
    const { currentVersion, uploader, ...trip } = await TripWithVersionApi.getWithVersion(tripId);
    await TripApi.updateTrip({ ...trip, status: trip.status === TripStatus.Active ? TripStatus.Inactive : TripStatus.Active });
  };

  public static cloneTripWithVersion = async (tripId: TripId) => {
    const { uploader, ...tripWithVersion } = await TripWithVersionApi.getWithVersion(tripId);
    const trip = {
      ...tripWithVersion,
      tripId: undefined,
      currentVersion: undefined,
      currentVersionId: undefined,
      status: TripStatus.Draft,
      active: false,
    };
    const version = {
      ...tripWithVersion.currentVersion,
      tripVersionId: undefined,
      title: tripWithVersion.currentVersion.title + ' (copy)',
    };

    return TripWithVersionApi.createTripWithVersion(trip, version);
  };

  static getTripOptions = async (options?: TripOptions): Promise<{ [key: string]: string }> => {
    const trips = await TripWithVersionApi.getWithVersionList();

    const includeSlug = options?.includeSlug ?? true;

    return _.mapValues(_.keyBy(trips, 'tripId'), v => (includeSlug ? `${v.currentVersion.title} (${v.currentVersion.slug})` : v.currentVersion.title));
  };

  public static getRecommendedTripOptions = async (): Promise<{ [key: string]: string }> => {
    let trips = await TripWithVersionApi.getWithVersionList();
    trips = trips.filter(trip => trip.status === TripStatus.Active && !trip.hidden);
    // Sort trips by country name and then by trip title.
    const sortedTrips = trips.sort((a, b) => {
      const countryNameA = isoCountryMap[a.currentVersion.country];
      const countryNameB = isoCountryMap[b.currentVersion.country];

      // Compare by country name.
      if (countryNameA < countryNameB) return -1;
      if (countryNameA > countryNameB) return 1;

      // If countries are the same, compare by trip title.
      if (a.currentVersion.title < b.currentVersion.title) return -1;
      if (a.currentVersion.title > b.currentVersion.title) return 1;

      return 0;
    });
    return _.mapValues(_.keyBy(sortedTrips, 'tripId'), v => `${isoCountryMap[v.currentVersion.country]} (${v.currentVersion.title})`);
  };

  public static getContentfulCategories = async (): Promise<{ [key: string]: string }> => {
    // call contentful API client to fetch categories
    const categories = await ContentfulApiClient.GetCategories();
    // return categories in an appropriate format
    return _.mapValues(_.keyBy(categories, 'sys.id'), v => v.fields.name as string);
  };

  public static getContentfulLandings = async (): Promise<{ [key: string]: string }> => {
    // call contentful API client to fetch landings
    const landings = await ContentfulApiClient.GetLandings();
    // return landings in an appropriate format
    return _.mapValues(_.keyBy(landings, 'fields.slug'), v => v.fields.title as string);
  };
}

export class TripApi {
  public static getTripsCSV = () => {
    return new Promise<string>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'getTripsCSV',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.data);
          } else {
            reject(resp.error);
          }
        },
      });
    });
  };
  public static getTrips = () =>
    new Promise<Trip[]>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getTrip = (tripId: TripId) =>
    new Promise<Trip>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'read',
        request: { entityIds: [tripId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createTrip = (trip: Omit<Trip, 'tripId'>) =>
    new Promise<TripId>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'create',
        request: { entity: trip },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateTrip = (trip: Trip) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'update',
        request: { entity: trip },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });

  public static archiveTrip = (tripId: TripId) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Trip',
        method: 'delete',
        request: { entityId: tripId },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class TripVersionApi {
  public static getTripVersions = () =>
    new Promise<TripVersion[]>((resolve, reject) => {
      RpcClient({
        controller: 'TripVersion',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getTripVersion = (tripVersionId: TripVersionId) =>
    new Promise<TripVersion>((resolve, reject) => {
      RpcClient({
        controller: 'TripVersion',
        method: 'read',
        request: { entityIds: [tripVersionId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createTripVersion = (tripVersion: Omit<TripVersion, 'tripVersionId'>) =>
    new Promise<TripVersionId>((resolve, reject) => {
      RpcClient({
        controller: 'TripVersion',
        method: 'create',
        request: { entity: tripVersion },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateTripVersion = (tripVersion: TripVersion) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'TripVersion',
        method: 'update',
        request: { entity: tripVersion },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });

  public static deleteTripVersion = (tripVersionId: TripVersionId) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'TripVersion',
        method: 'delete',
        request: { entityId: tripVersionId },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class ReviewApi {
  public static getReviewList = (tripId: TripId) =>
    new Promise<Review[]>((resolve, reject) => {
      RpcClient({
        controller: 'Review',
        method: 'read',
        request: { filters: { tripId } },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getReview = (reviewId: ReviewId) =>
    new Promise<Review>((resolve, reject) => {
      RpcClient({
        controller: 'Review',
        method: 'read',
        request: { entityIds: [reviewId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createReview = (review: Omit<Review, 'reviewId'>) =>
    new Promise<ReviewId>((resolve, reject) => {
      RpcClient({
        controller: 'Review',
        method: 'create',
        request: { entity: review },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateReview = (review: Review) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Review',
        method: 'update',
        request: { entity: review },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });

  public static deleteReview = (reviewId: ReviewId) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Review',
        method: 'delete',
        request: { entityId: reviewId },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class CouponApi {
  public static getCouponList = () =>
    new Promise<Coupon[]>((resolve, reject) => {
      RpcClient({
        controller: 'Coupon',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getCoupon = (couponId: CouponId) =>
    new Promise<Coupon>((resolve, reject) => {
      RpcClient({
        controller: 'Coupon',
        method: 'read',
        request: { entityIds: [couponId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static createCoupon = (coupon: Coupon) =>
    new Promise<CouponId>((resolve, reject) => {
      RpcClient({
        controller: 'Coupon',
        method: 'create',
        request: { entity: coupon },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject();
          }
        },
      });
    });

  public static updateCoupon = (coupon: Coupon) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Coupon',
        method: 'update',
        request: { entity: coupon },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });

  public static deleteCoupon = (couponId: CouponId) =>
    new Promise<void>((resolve, reject) => {
      RpcClient({
        controller: 'Coupon',
        method: 'delete',
        request: { entityId: couponId },
        cb: resp => {
          if (resp.status === 'success') {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
}

export class ReservationApi {
  public static getReservations = () =>
    new Promise<Reservation[]>((resolve, reject) => {
      RpcClient({
        controller: 'Reservation',
        method: 'read',
        request: {},
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static getReservation = (reservationId: ReservationId) =>
    new Promise<Reservation>((resolve, reject) => {
      RpcClient({
        controller: 'Reservation',
        method: 'read',
        request: { entityIds: [reservationId] },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entities[0]);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static updateReservation = (reservation: Reservation) =>
    new Promise<ReservationId>((resolve, reject) => {
      RpcClient({
        controller: 'Reservation',
        method: 'update',
        request: { entity: reservation },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject(resp.error);
          }
        },
      });
    });

  public static cancelReservation = (id: ReservationId) =>
    new Promise<ReservationId>((resolve, reject) => {
      RpcClient({
        controller: 'Reservation',
        method: 'cancelReservation',
        request: { id },
        cb: resp => {
          if (resp.status === 'success') {
            resolve(resp.result.entityId);
          } else {
            reject(resp.error);
          }
        },
      });
    });
}
